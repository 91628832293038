// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-amp-index-tsx": () => import("./../../../src/pages/amp/index.tsx" /* webpackChunkName: "component---src-pages-amp-index-tsx" */),
  "component---src-pages-en-amp-index-tsx": () => import("./../../../src/pages/en/amp/index.tsx" /* webpackChunkName: "component---src-pages-en-amp-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-en-book-page-template-tsx": () => import("./../../../src/templates/en/book-page.template.tsx" /* webpackChunkName: "component---src-templates-en-book-page-template-tsx" */),
  "component---src-templates-en-game-page-template-tsx": () => import("./../../../src/templates/en/game-page.template.tsx" /* webpackChunkName: "component---src-templates-en-game-page-template-tsx" */),
  "component---src-templates-en-movie-page-template-tsx": () => import("./../../../src/templates/en/movie-page.template.tsx" /* webpackChunkName: "component---src-templates-en-movie-page-template-tsx" */),
  "component---src-templates-it-book-page-template-tsx": () => import("./../../../src/templates/it/book-page.template.tsx" /* webpackChunkName: "component---src-templates-it-book-page-template-tsx" */),
  "component---src-templates-it-game-page-template-tsx": () => import("./../../../src/templates/it/game-page.template.tsx" /* webpackChunkName: "component---src-templates-it-game-page-template-tsx" */),
  "component---src-templates-it-movie-page-template-tsx": () => import("./../../../src/templates/it/movie-page.template.tsx" /* webpackChunkName: "component---src-templates-it-movie-page-template-tsx" */)
}

